import React from "react";
import { Card, CardBody, Row, Col, Media, Button } from "reactstrap";
import avatar_picture from "../../../../../assets/img/portrait/small/avatar-s-18.jpg";
import {useTrad} from "../../../../../utility/traduction"
export default function PreviewCard({ title, subtitle, caption, media, onGoBack }) {
  const t = useTrad("HibimAccount_register_PreviewCard")
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs="4">
            <Media className="mr-2 my-25" left href="#">
              <Media
                className="rounded"
                object
                src={media ? media.url : avatar_picture}
                alt="Avatar"
                height="120"
                width="120"
              />
            </Media>
          </Col>
          <Col className="my-auto">
            <div className="h4 mb-0">{title}</div>
            <div className="">{subtitle}</div>
            <div className="light">{caption}</div>
          </Col>
          {onGoBack && (
            <Button onClick={onGoBack} color="link" className="position-absolute" style={{ bottom: 0, right: 0 }}>
              {t("goBack","Revenir au profil")}
            </Button>
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
