import React from "react";
import NumericInput from "react-numeric-input";
import { mobileStyle as InputStyles } from "components/@vuexy/forms/form-elements/number-input/InputStyles";
import { FormGroup } from "reactstrap";

export default function FieldNumberElementComponent({ element, formData, onChange }) {
  const handleChange = value => {
    onChange({ target: { name: element.name, value } });
  };
  return (
    <FormGroup row>
      <span className="mr-2">{element.placeholder}</span>
      <NumericInput
        name={element.name}
        min={1}
        max={100}
        value={formData[element.name] || 1}
        mobile
        onChange={handleChange}
        style={InputStyles}
      />
    </FormGroup>
  );
}
