import React, { useState } from "react";
import { Form, Button } from "reactstrap";
import { FIELD_MEDIA, getElementComponent } from "../../configs/elements";
import { useTrad } from "../../utility/traduction";

function compare(a, b) {
  const orderA = a.order || 0;
  const orderB = b.order || 0;
  if (orderA !== orderB) {
    return orderA > orderB ? 1 : -1;
  } else {
    return 0;
  }
}

export default function FormElementComponent({ page, element, onSubmit, onError }) {
  const t = useTrad("HibimAccount_register");

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);

  const handleChange = e => {
    if (e?.target?.type === "checkbox") {
      setFormData({ ...formData, [e.target.name]: e.target.checked });
    } else if (e?.target) {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setErrors(errors.filter(err => err.name !== e.target.name));
    } else {
      console.log("invalid form change", e);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let errors = element.elements.filter(elt => elt.type === FIELD_MEDIA && elt.isRequired && !formData[elt.name]);
    setErrors(errors);
    if (errors.length === 0) {
      onSubmit(formData);
    }
  };

  const renderElement = element => {
    const ElementComponent = getElementComponent(element.type);
    return (
      <ElementComponent
        key={element.id}
        element={element}
        formData={formData}
        errors={errors}
        onChange={handleChange}
      />
    );
  };

  return (
    <Form className="mt-3" method="post" onSubmit={handleSubmit}>
      {element.elements.sort(compare).map(sub => renderElement(sub))}
      <Button.Ripple color="primary" className="ml-0" disabled={false}>
        {t("finish", "Terminer")}
      </Button.Ripple>
    </Form>
  );
}
