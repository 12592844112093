import { api, setupToken } from "api";
import hashToFormData from "./ApiDataSerializer";

export const submitForm = async (data, page, space) => {
  setupToken();
  let formData = hashToFormData(data, "data");

  const response = await api.post(
    `${process.env.REACT_APP_API_URL}/api/spaces/${space.slug}/pages/${page.slug}/page_form_datas`,
    formData,
  );
  return response.data;
};
