import React from "react";
import { Smartphone } from "react-feather";
import { FormGroup, Input } from "reactstrap";

export default function FieldPhoneElementComponent({ element, formData, onChange }) {
  return (
    <FormGroup row className="has-icon-left position-relative">
      <Input
        type="number"
        name="phone"
        value={formData[element.name]}
        placeholder={`${element.placeholder}${element.isRequired ? "*" : ""}`}
        required={!!element.isRequired}
        onChange={onChange}
      />
      <div className="form-control-position">
        <Smartphone size={15} />
      </div>
    </FormGroup>
  );
}
