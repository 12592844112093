import forEach from 'lodash/forEach';
import isDate from 'lodash/isDate';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
/*
convert an {} to a form data compatible with rails format
*/
const hashToFormData = (hash, path) => {
  const formData = new FormData();

  forEach(hash, (value, key) => {
    if (isArray(value)) {
      forEach(value, arrayValue => {
        formData.append(`${path}[${key}][]`, arrayValue);
      });
    } else if (isDate(value)) {
      formData.append(`${path}[${key}]`, value);
    } else if (value instanceof Blob || value instanceof File) {
      formData.append(`${path}[${key}]`, value, value.name || 'file');
    } else if (isObject(value)) {
      forEach(value, (hashValue, hashKey) => {
        if (isArray(hashValue)) {
          forEach(hashValue, arrayValue => {
            formData.append(`${path}[${key}][${hashKey}][]`, arrayValue);
          });
        } else {
          formData.append(`${path}[${key}][${hashKey}]`, hashValue);
        }
      });
    } else {
      formData.append(`${path}[${key}]`, value);
    }
  });
  return formData;
};

export default hashToFormData;
