import {
  REQUEST_LOGIN,
  SUCCESS_LOGIN,
  ERROR_LOGIN,
  CLEAR,
  GET_CURRENT_USER,
  SET_CURRENT_USER,
  UPDATE_AUTH
} from "../../actions/auth";

const auth = (state = {}, action) => {
  switch (action.type) {
    case SUCCESS_LOGIN:
      return {
        ...state,
        ...action.auth,
      };

    case UPDATE_AUTH:
      return {...state, ...action.payload}

    case REQUEST_LOGIN:
      return {
        ...state,
        ...action.auth,
      };

    case ERROR_LOGIN:
      return {
        ...state,
        ...action.auth,
      };

    case CLEAR:
      return {};

    case GET_CURRENT_USER:
      if (action.success === null) {
        return {
          ...state,
          userLoading: true,
        };
      }

      if (!action.success) {
        return {
          ...state,
          userLoading: false,
        };
      }
      let instance = null;
      if (action.data && action.data["hydra:member"] && action.data["hydra:member"].length) {
        instance = action.data["hydra:member"][0];
      }
      return {
        ...state,
        user: instance,
        userLoading: false,
      };

    case SET_CURRENT_USER: {
      return { ...state, user: { ...state.user, ...action.payload } };
    }

    default:
      return state;
  }
};

export default auth;
