import { combineReducers } from "redux";
import auth from "./auth";
import init from "./init";
import spaces from "./space";
import service from "./service";
import pages from "./pages";
import traduction from "./traduction";
import users from "./users"

const rootReducer = combineReducers({
  init,
  auth,
  spaces,
  service,
  pages,
  traduction,
  users
});

export default rootReducer;
