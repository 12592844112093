import React, { useEffect } from "react";
import Router from "./Router";
import "./components/@vuexy/rippleButton/RippleButton";
import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import "react-toggle/style.css";
import "./assets/scss/plugins/forms/switch/react-toggle.scss";

import { useSelector, useDispatch } from "react-redux";
import { authenticate } from "./redux/actions/auth";
import { changeLang } from "./redux/actions/traduction";
import { acceptedLocales } from "./configs/traduction";

const App = props => {
  const dispatch = useDispatch();
  // const history = useHistory(); // cannot use history outside router !
  const logged = useSelector(state => state.auth.isLogged);
  const pending = useSelector(state => state.auth.pending);
  const auth = useSelector(state => state.auth);
  // const lang = new URLSearchParams(history?.location?.search).get("lang");

  /** autheticate user on first load */
  useEffect(() => {
    if (!logged && !pending) {
      dispatch(authenticate());
    }
  }, [dispatch, logged, pending]);

  useEffect(() => {
    if (auth?.user?.languages) {
      let locale = auth.user.languages[0];
      if (locale && acceptedLocales.includes(locale)) {
        dispatch(changeLang(locale));
      }
    } 
  }, [auth, dispatch]);

  return <Router />;
};

export default App;
