import { REQUEST_GET_PAGES_BY_TEMPLATE, REQUEST_GET_PAGE_ELEMENTS } from "../../actions/pages";

const INITIAL_STATE = {
  pages: [],
  current: null,
  status: {
    getPages: null,
    getElements: null,
  },
};

const componentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_GET_PAGES_BY_TEMPLATE: {
      if (action.success) {
        return { ...state, status: { ...state.status, getPages: "success" }, current: action.data["hydra:member"][0] };
      }
      return { ...state, status: { ...state.status, getPages: action.success === null ? "fetching" : "error" } };
    }
    case REQUEST_GET_PAGE_ELEMENTS: {
      if (action.success) {
        return { ...state, status: { ...state.status, getElements: "success" }, elements: action.data["hydra:member"] };
      }
      return { ...state, status: { ...state.status, getElements: action.success === null ? "fetching" : "error" } };
    }
    default:
      return state;
  }
};

export default componentsReducer;
