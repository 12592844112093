import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import Maintenance from "./views/misc/Maintenance";
import App from "./App";

const FinalApp = () => {
	return process.env.REACT_APP_MAINTENANCE_MODE === "true" ? (
		<Maintenance />
	) : (
		<Provider store={store}>
			<Suspense fallback={<Spinner />}>
				<App />
			</Suspense>
		</Provider>
	);
};

// configureDatabase()
ReactDOM.render(<FinalApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
