export const STORE_REDIRECT_URL = "INIT.STORE_REDIRECT_URL";

export function storeRedirectURL(url) {
  return (dispatch) => {
    dispatch({
      type: STORE_REDIRECT_URL,
      payload:url
    });
  };
}

export function getRedirectUrl(state) {
  const { init } = state;
  if (init && init.redirect) {
    return init.redirect;
  }
  return null;
}
