import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Trash2 } from "react-feather";
import { Button, FormGroup, Label, Alert } from "reactstrap";

function DropzoneAccepted({ element, formData, onChange }) {
  const [files, setFiles] = useState(formData[element.name] ? [formData[element.name]] : []);
  const accept = element?.options?.accept || "image/jpeg, image/png";
  const { open, getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
      onChange(acceptedFiles);
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  return (
    <section>
      <div className="d-flex">
        <div {...getRootProps({ className: "dropzone dropzone-dark" })}>
          <input name={element.name} {...getInputProps()} />
          <p className="mx-1">
            Drag 'n' drop votre image ici, ou selectionner un fichier <em>(Only {accept} files will be accepted)</em>
          </p>
        </div>
        <aside className="thumb-container">
          {files.map(file => (
            <div className="dz-thumb" key={file.name}>
              <div className="dz-thumb-inner">
                <img src={file.preview} className="dz-img" alt={file.name} />
              </div>
              <Button
                size="sm"
                color="danger"
                outline
                className="my-1"
                onClick={() => {
                  setFiles([]);
                  onChange([]);
                }}
              >
                <Trash2 size={12} />
              </Button>
            </div>
          ))}
        </aside>
      </div>
      <Button color="primary" size="sm" outline className="my-1" onClick={open}>
        Choisir une image
      </Button>
    </section>
  );
}

export default function FieldMediaElementComponent({ element, formData, errors, onChange }) {
  const handleChange = value => {
    onChange({ target: { name: element.name, type: "file", value: value[0] } });
  };
  return (
    <FormGroup row>
      <Label className="font-size-base font-weight-normal mb-1 mr-2">
        {element.placeholder} {element.isRequired ? "*" : ""}
      </Label>
      {errors.find(err => err.name === element.name) && (
        <Alert className="block mb-2" color="danger" isOpen>
          Champ obligatoire
        </Alert>
      )}
      <DropzoneAccepted element={element} formData={formData} onChange={handleChange} />
    </FormGroup>
  );
}
