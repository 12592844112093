export const REQUEST_GET_PAGES_BY_TEMPLATE = "PAGES.REQUEST_GET_PAGES_BY_TEMPLATE";
export const REQUEST_GET_PAGE_ELEMENTS = "PAGES.REQUEST_GET_PAGE_ELEMENTS";

export function getPagesByTemplate(template, space) {
  return (dispatch, getState) => {
    if (!space) {
      return null;
    }
    dispatch({
      type: REQUEST_GET_PAGES_BY_TEMPLATE,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages?space=${space.id}&template=${template}`,
        method: "GET",
      },
    });
  };
}

export function getPageElements(id) {
  return (dispatch, getState) => {
    dispatch({
      type: REQUEST_GET_PAGE_ELEMENTS,
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/pages/${id}/elements`,
        method: "GET",
      },
    });
  };
}
