import { api } from "../../../api";

export const FETCH_SERVICE = "FETCH_SERVICE";
export const SET_SERVICE_OPTIONS = "SET_SERVICE_OPTIONS";
export const SET_CURRENT_SERVICE = "SET_CURRENT_SERVICE";
export const CLEAR = "SERVICE.CLEAR";

export const setCurrentService = service => {
  return { type: SET_CURRENT_SERVICE, service };
};

export const setServiceOptions = serviceOptions => {
  return { type: SET_SERVICE_OPTIONS, serviceOptions };
};

export const fetchService = spaceTypeSlug => {
  return async (dispatch, getState) => {
    if (spaceTypeSlug) {
      dispatch({ type: FETCH_SERVICE, status: "fetching" });

      try {
        let services = await api
          .get(`/api/space_type_forms?spaceType.slug=${spaceTypeSlug}`)
          .then(res => res.data)
          .then(res => res["hydra:member"]);
        console.log(services);

        dispatch({
          type: FETCH_SERVICE,
          status: "success",
        });

        dispatch(setCurrentService(services[0]));

        const options = await api
          .get(`/api/space_type_form_values?spaceType.slug=${spaceTypeSlug}`)
          .then(res => res.data)
          .then(res => res["hydra:member"]);

        console.log(options);
        dispatch(setServiceOptions(options));

        dispatch({
          type: FETCH_SERVICE,
          status: "success",
        });

        return services;
      } catch (error) {
        dispatch({ type: FETCH_SERVICE, status: "error", error });
        return error;
      }
    }
  };
};

export const reset = () => {
  return {
    type: CLEAR,
  };
};

export const getErrorFromState = state => {
  const { space } = state;
  if (space && space.error) {
    return space.error;
  }
  return null;
};

export const getCreatingFromState = state => {
  const { space } = state;
  if (space && space.creating) {
    return space.creating;
  }
  return false;
};

export const getNewSpaceFromState = state => {
  const { space } = state;
  if (space && space.newSpace) {
    return space.newSpace;
  }
  return null;
};
