import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Switch, useLocation, Route } from "react-router-dom";
import Spinner from "./components/@vuexy/spinner/Loading-spinner";
import FullPageLayout from "./layouts/FullpageLayout";
import BlankLayout from "./layouts/BlankLayout";
import qs from "query-string";
import { useDispatch } from "react-redux";
import { storeRedirectURL } from "./redux/actions/init";
import { updateAuth } from "./redux/actions/auth";
import ServiceForm from "./views/pages/authentication/register/ServiceForm";
import MembershipForm from "./views/pages/authentication/register/MemberShipForm";

const Login = lazy(() => import("./views/pages/authentication/login/Login"));

const Logout = lazy(() => import("./views/pages/authentication/logout/Logout"));

const Create = lazy(() => import("./views/pages/authentication/create/Create"));

const Join = lazy(() => import("./views/pages/authentication/join/Join"));

const Invite = lazy(() => import("./views/pages/authentication/invite/Invite"));

const Register = lazy(() => import("./views/pages/authentication/register/Register"));

const Forgot = lazy(() => import("./views/pages/authentication/recover/ForgotPassword"));

const Reset = lazy(() => import("./views/pages/authentication/recover/ResetPassword"));

const Comment = lazy(() => import("./views/pages/authentication/comment/Comment"));
const Error404 = lazy(() => import("./views/misc/error/404"));

const VerificationCode = lazy(() => import("./views/pages/verification/VerificationCode"));

const AppRoute = ({ component: Component, fullLayout, ...props }) => {
  if (fullLayout) {
    return (
      <FullPageLayout>
        <Suspense fallback={<Spinner />}>
          <Component {...props} />
        </Suspense>
      </FullPageLayout>
    );
  }
  return (
    <BlankLayout>
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    </BlankLayout>
  );
};

const SwitchApp = () => {
  let location = useLocation();
  let dispatch = useDispatch();

  useEffect(() => {
    // update redirect url
    let query = qs.parse(location.search);
    if (query.redirect) {
      dispatch(storeRedirectURL(query.redirect));
    }
    if(query.email)
    {
      dispatch(updateAuth({email:query.email}))
    }
  }, [location.search, dispatch]);

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/register/service" component={ServiceForm} />
      <Route path="/register/membership" component={MembershipForm} />
      <Route path="/register" component={Register} />
      <Route path="/forgot" component={Forgot} />
      <Route path="/reset" component={Reset} />
      <Route path="/verify" component={VerificationCode} />
      <AppRoute path="/logout" component={Logout} fullLayout />
      <Route path="/join" component={Join} />
      <Route path="/create" component={Create} />
      <AppRoute path="/invite" component={Invite} />
      <AppRoute path="/comment" component={Comment} />
      {/*<Redirect to="/" />*/}
      <Route component={Error404} />
    </Switch>
  );
};

const AppRouter = () => {
  return (
    // Set the directory path if you are deploying in sub-folder
    <Router>
      <SwitchApp />
    </Router>
  );
};

export default AppRouter;
