import React from "react";
import { FormGroup } from "reactstrap";
import { Input } from "reactstrap";

export default function FieldTextElementComponent({ element, formData, onChange }) {
  return (
    <FormGroup row>
      <Input
        name={element.name}
        value={formData[element.name]}
        placeholder={`${element.placeholder}${element.isRequired ? "*" : ""}`}
        required={!!element.isRequired}
        onChange={onChange}
      />
    </FormGroup>
  );
}
