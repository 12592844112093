// tooling modules
import axios from "axios";
import Cookies from "js-cookie";

// configuration
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const basicApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let token = Cookies.get("token");
if (token) api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const setupToken = () => {
  let token = Cookies.get("token");
  if (token) api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

setupToken();

export { default as simpleFetch } from "./simpleFetch";
export { api, basicApi, setupToken };
