import React from "react";
import CheckBoxesVuexy from "components/@vuexy/checkbox/CheckboxesVuexy";
import { Check } from "react-feather";
import { FormGroup } from "reactstrap";

export default function FieldCheckboxElementComponent({ element, formData, onChange }) {
  return (
    <FormGroup row>
      <CheckBoxesVuexy
        className="mb-2 font-size-base font-weight-normal"
        color="primary"
        icon={<Check className="vx-icon" size={16} />}
        label={`${element.placeholder}${element.isRequired ? "*" : ""}`}
        name={element.name}
        checked={formData[element.name]}
        onChange={onChange}
      />
    </FormGroup>
  );
}
