const API_URL = process.env.REACT_APP_API_URL || "";

/**
 * a method to fetch the api
 * @param {string} path - the API path
 * @param {object} options - the options
 * @returns {Promise}
 */
const simpleFetch = (path, options = {}, token = null) => {
  const headers = (options.headers = options.headers || {});
  if (token) {
    headers["Authorization"] = token;
  }

  headers["Content-Type"] = "application/json";

  options.method = options.method || "GET";
  if (options.method !== "GET") {
    options.body = options.data ? JSON.stringify(options.data) : {};
  }
  delete options.data;

  return fetch(`${API_URL}${path}`, options)
    .then(async (response) => {
      const contentType = response.headers.get("Content-Type") || "";
      if (!response.ok) {
        if (
          contentType.indexOf("application/json") === -1 &&
          contentType.indexOf("application/ld+json") === -1
        ) {
          throw new Error("Api Error");
        }
        throw await response.json();
      }

      if (
        contentType.indexOf("application/json") === -1 &&
        contentType.indexOf("application/ld+json") === -1
      ) {
        return null;
      }
      response = await response.json();
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export default simpleFetch;
