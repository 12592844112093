import React from "react";
import { Container, Row, Button } from "reactstrap";
import { ChevronLeft, X } from "react-feather";
import { useHistory } from "react-router-dom";

const BlankLayout = ({ children, ...rest }) => {
  const history = useHistory();

  return (
    <div className="full-layout wrapper blank-page dark-layout">
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <Container fluid>
              <Row className="d-flex justify-content-between mx-1 mt-1">
                <Button
                  color="light"
                  size="sm"
                  className="px-1 py-1"
                  onClick={() => history.goBack()}
                >
                  <ChevronLeft size={20} color="white" />
                </Button>
                <Button
                  color="light"
                  size="sm"
                  className="px-1 py-1"
                  onClick={() => history.goBack()}
                >
                  <X size={20} color="white" />
                </Button>
              </Row>
            </Container>
            <div className="flexbox-container">
              <main className="main w-100">{children}</main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlankLayout;
