import { simpleFetch, api } from "../../../api";
import Cookies from "js-cookie";

export const REQUEST_LOGIN = "AUTH.REQUEST_LOGIN";
export const SUCCESS_LOGIN = "AUTH.SUCCESS_LOGIN";
export const ERROR_LOGIN = "AUTH.ERROR_LOGIN";
export const CLEAR = "AUTH.CLEAR";
export const GET_CURRENT_USER = "AUTH.GET_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_AUTH = "AUTH.UPDATE_AUTH";

export function getToken({ auth }) {
  if (!auth) {
    return null;
  }
  return auth.token;
}

export function requestLogin() {
  return {
    type: REQUEST_LOGIN,
    auth: {
      pending: true,
      isLogged: false,
      error: undefined,
    },
  };
}

export function successLogin({ token, email }) {
  Cookies.set("token", token, { domain: process.env.REACT_APP_DOMAIN_NAME });
  Cookies.set("email", email, { domain: process.env.REACT_APP_DOMAIN_NAME });

  return {
    type: SUCCESS_LOGIN,
    auth: {
      token,
      email,
      isLogged: true,
      pending: false,
    },
  };
}

export function successAuth({ token, email }) {
  Cookies.set("token", token, { domain: process.env.REACT_APP_DOMAIN_NAME });
  Cookies.set("email", email, { domain: process.env.REACT_APP_DOMAIN_NAME });

  return {
    type: SUCCESS_LOGIN,
    auth: {
      token,
      isLogged: true,
      pending: false,
    },
  };
}

export function errorLogin(error) {
  Cookies.remove("token", { domain: process.env.REACT_APP_DOMAIN_NAME });
  Cookies.remove("email", { domain: process.env.REACT_APP_DOMAIN_NAME });
  // document.cookie = `@hibim_auth=; Domain=${process.env.REACT_APP_DOMAIN_NAME}; path=/;`;
  // document.cookie = `@hibim_email=; Domain=${process.env.REACT_APP_DOMAIN_NAME}; path=/;`;
  return {
    type: ERROR_LOGIN,
    auth: {
      pending: false,
      isLogged: false,
      error: error,
    },
  };
}

export function clear() {
  Cookies.remove("token", { domain: process.env.REACT_APP_DOMAIN_NAME });
  Cookies.remove("email", { domain: process.env.REACT_APP_DOMAIN_NAME });
  // document.cookie = `@hibim_auth=; Domain=${process.env.REACT_APP_DOMAIN_NAME}; path=/;`;
  // document.cookie = `@hibim_email=; Domain=${process.env.REACT_APP_DOMAIN_NAME}; path=/;`;
  return {
    type: CLEAR,
  };
}

/**
 * dispatches log attempt
 * @param {Object} data - the json data to pass
 **/
export function login(data) {
  return (dispatch, getState) => {
    dispatch(requestLogin());
    const options = {
      method: "POST",
      data,
    };

    return simpleFetch("/login_check", options)
      .then(res => {
        dispatch(successLogin({ token: res.token, email: data.username }));
      })
      .catch(err => {
        dispatch(errorLogin(err));
      });
  };
}

/**
 * dispatches clear and remove local storage
 **/
export function logout() {
  return dispatch => {
    //localStorage.removeItem('@hibimAuthentication');
    dispatch(clear());
  };
}

/**
 * get logged status
 */
export function isLogged(state) {
  const { auth } = state;
  return Boolean(auth && auth.isLogged);
}

/**
 * get pending status
 */
export function isPending(state) {
  const { auth } = state;
  return Boolean(auth && auth.pending);
}

/**
 * get error
 */
export function getError(state) {
  const { auth } = state;
  if (auth && auth.error) {
    return auth.error;
  }
  return null;
}

export function getCurrentUser(email) {
  return {
    type: GET_CURRENT_USER,
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/users?email=${encodeURIComponent(email)}`,
      method: "GET",
    },
  };
}

export const setCurrentUser = data => {
  return { type: SET_CURRENT_USER, payload: data };
};

export const updateCurrentUser = data => async (dispatch, getState) => {
  try {
    let { user = {}, token } = getState().auth;
    let userID = user.id;

    if (userID) {
      await api.put(`/api/user/${userID}`, data, { headers: { Authorization: "Bearer " + token } });
      dispatch(setCurrentUser(data));
      return { success: true };
    } else {
      throw new Error("no user defined in state.auth.user");
    }
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};

export const updateCurrentUserAvatar = data => async (dispatch, getState) => {
  try {
    let { user = {}, token } = getState().auth;
    let userID = user.id;

    if (userID) {
      let { id, objectUrl } = data;
      let pathId = "/api/media/" + id;

      await api.put(`/api/user/${userID}`, { photo: pathId }, { headers: { Authorization: "Bearer " + token } });
      dispatch(setCurrentUser({ photo: { id, url: objectUrl } }));
      return { success: true };
    } else {
      throw new Error("no user defined in state.auth.user");
    }
  } catch (error) {
    console.log(error);
    return { success: false, error };
  }
};

export function authenticate() {
  return (dispatch, getState) => {
    const token = Cookies.get("token");
    const email = Cookies.get("email");

    if (token && email) {
      dispatch(successLogin({ token, email }));
      dispatch(getCurrentUser(email))
    } else {
      // dispatch(errorLogin("unable to authenticate"));
      console.log("could not authenticate");
    }
  };
}

export function isUserLoading(state) {
  if (state.auth) {
    return Boolean(state.auth.userLoading);
  }
  return false;
}

export function getCurrentUserFromState(state) {
  if (state.auth) {
    return state.auth.user;
  }
  return null;
}

export function getBearerTokenFromState(state) {
  if (state.auth && state.auth.token) {
    return state.auth.token;
  }
  return null;
}


export const updateAuth = payload => ({type: UPDATE_AUTH, payload})