import { simpleFetch, api } from "../../../api";
import Cookies from "js-cookie";
import { slugify } from "../../../utility/spaceUtil";

export const FETCH_SPACES = "FETCH_SPACES";
export const SET_SPACES = "SET_SPACES";
export const SET_CURRENT_SPACE = "SET_CURRENT_SPACE";
export const REQUEST_CREATE_SPACE = "SPACE.REQUEST_CREATE_SPACE";
export const UPDATE_CURRENT_SPACE = "UPDATE_CURRENT_SPACE";
export const CLEAR = "SPACE.CLEAR";

export const setCurrentSpace = space => {
  return { type: SET_CURRENT_SPACE, space };
};

export const setSpaces = spaces => {
  return { type: SET_SPACES, spaces };
};

export function createSpace(data) {
  return dispatch => {
    let name = slugify(data.name);
    const options = {
      method: "POST",
      data: {
        ...data,
        slug: name,
        category: "",
        langSpace: "",
        spaceAddress: name,
        name: name,
      },
    };

    dispatch({
      type: REQUEST_CREATE_SPACE,
      success: null,
    });

    const token = Cookies.get("token");
    simpleFetch(`/api/space`, options, `Bearer ${token}`)
      .then(res => {
        dispatch({
          type: REQUEST_CREATE_SPACE,
          success: true,
          data: res,
        });
        dispatch({
          type: SET_CURRENT_SPACE,
          space: res,
        });
      })
      .catch(e => {
        dispatch({
          type: REQUEST_CREATE_SPACE,
          success: false,
          error: e,
        });
      });
  };
}

export const fetchSpaces = (fetchAll = false) => {
  return async (dispatch, getState) => {
    const user = getState().auth.user;
    dispatch({ type: FETCH_SPACES, status: "fetching" });

      try {
        let query = "/api/space";
        if(!fetchAll && user)
        {
          query += `?user=${user.id}`
        }
        const res = await api
          .get(query)
          .then(res => res.data)
          .then(res => res["hydra:member"]);

        const spaces = transformSpaces(res, getState);

        dispatch({
          type: FETCH_SPACES,
          status: "success",
        });

        dispatch(setSpaces(spaces));

        return spaces;
      } catch (error) {
        dispatch({ type: FETCH_SPACES, status: "error", error });
        console.log("fetchSpaces error", error)
        return error;
      }
  }
};



export const updateCurrentSpace = data => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_CURRENT_SPACE, status: "fetching" });
    let currentSpace = getState().spaces.currentSpace;
    const res = await api.put("/api/space/" + currentSpace.id, data);
    dispatch({ type: UPDATE_CURRENT_SPACE, data: res.data, status: "success" });
  } catch (e) {
    dispatch({ type: UPDATE_CURRENT_SPACE, error: e, status: "error" });
    throw new Error("Error updating Space");
  }
};

export const reset = () => {
  return {
    type: CLEAR,
  };
};

export const getErrorFromState = state => {
  const { space } = state;
  if (space && space.error) {
    return space.error;
  }
  return null;
};

export const getCreatingFromState = state => {
  const { space } = state;
  if (space && space.creating) {
    return space.creating;
  }
  return false;
};

export const getNewSpaceFromState = state => {
  const { space } = state;
  if (space && space.newSpace) {
    return space.newSpace;
  }
  return null;
};

const transformSpaces = (data, getState) => {
  const userID = getState().auth.user ? getState().auth.user.id : null;
  const spaces = data.map(elem => {
    // retrieve elem id from string "/api/space/{id}"
    let spaceId = parseInt(elem["@id"].match(/\d+/g)[0]);
    let spaceUserId = parseInt(elem["user"].match(/\d+/g)[0]);
    elem.id = spaceId;
    // elem.type = elem["@type"];
    elem.userRole = spaceUserId === userID ? "admin" : "member";
    delete elem["@id"];
    delete elem["@type"];
    return elem;
  });
  return spaces;
};
