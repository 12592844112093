import React, { useState } from "react";
import { Row, Col, Alert, Form, FormGroup, Input, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import NumericInput from "react-numeric-input";

import { updateCurrentSpace } from "../../../../redux/actions/space";

import PreviewCard from "./partials/PreviewCard";

import { defaultStyle } from "../../../../components/@vuexy/forms/form-elements/number-input/InputStyles";
import { fetchService } from "../../../../redux/actions/service";

import serviceTypeOptions from "../../../../configs/serviceTypeOptions.json";
import "../../../../assets/scss/pages/authentication.scss";
import logo from "../../../../assets/img/logo/hibim-logo.png";
import {useTrad} from "../../../../utility/traduction"

export default function ServiceForm({ onFinalStep }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitAlert, setSubmitAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const t = useTrad("HibimAccount_register_ServiceForm")
  
  const user = useSelector(state => state.auth.user) || {};
  const isFetching = useSelector(state => state.spaces.isFetching);
  const currentSpace = useSelector(state => state.spaces.currentSpace);

  const currentService = useSelector(state => state.service.currentService);
  const serviceOptions = useSelector(state => state.service.serviceOptions);

  const [data, setData] = useState({});
  const serviceType = new URLSearchParams(history.location.search).get("type");

  React.useEffect(() => { 
    dispatch(fetchService(serviceType));
  }, [dispatch, serviceType]);

  if (!serviceType) {
    onFinalStep && onFinalStep();
    return null;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      // TODO: format data to save into space
      const dataUpdated = {
        dailyRate: data.dailyRate,
        levelOfExperience: data.levelOfExperience,
        skill: data.skill,
        businessLine: data.businessLine,
        availability: data.availability,
        langSpace: data.language,
        eventType: data.eventType,
        participantsNumber: data.participantsNumber,
        musicGenre: data.musicGenre,
        locationType: data.locationType,
        services: data.services,
        extraServices: data.extraServices,
        budget: data.budget,
        coverageArea: data.coverageArea,
      };
      console.log(dataUpdated);
      await dispatch(updateCurrentSpace({ ...dataUpdated }));
      onFinalStep();
    } catch (e) {
      console.log(e);
      setSubmitAlert(true);
    }
  };

  console.log(currentService);
  const isFieldEnabled = fieldName => currentService && currentService[fieldName];
  const getFieldOptions = fieldName => {
    let options = (serviceOptions && serviceOptions.filter(option => option.field === fieldName)) || [];
    if (options.length === 0) {
      options = serviceTypeOptions[fieldName];
    }
    return options;
  };

  return (
    <div className="vh-100">
      <header className="py-1 px-5 shadow-sm bg-white">
        <img src={logo} alt="" />
      </header>
      <Row className="h-100">
        <Col className="h-100 p-5 bg-white">
          <h1>{t("title1","Un peu plus sur le service que vous proposez")}</h1>
          <p>{t("key1","Let others get to know you better ! You can do these later")}</p>
          <Row>
            <Col sm="12">
              <Alert
                className="mb-2"
                color="warning"
                isOpen={submitAlert}
                toggle={() => {
                  setSubmitAlert(false);
                }}
              >
                <p className="mb-0">{t("error","Il semble qu'il y ait eu une erreur...")}</p>
              </Alert>
              <Alert
                className="mb-2"
                color="success"
                isOpen={successAlert}
                toggle={() => {
                  setSuccessAlert(false);
                }}
              >
                <p className="mb-0">{t("success","Les modifications ont bien étés apportées !")}</p>
              </Alert>
            </Col>
          </Row>
          <Form onSubmit={handleSubmit} className="py-2">
            {isFieldEnabled("dailyRate") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("tarif","Tarif / jour (€ HT)")}</span>
                </Col>
                <Col md="8">
                  <NumericInput
                    min={0}
                    max={10000}
                    value={0}
                    name="dailyRate"
                    format={num => (num === 0 ? t("notDefined","Non défini", true) : `${num} €/j`)}
                    style={defaultStyle}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("levelOfExperience") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("xp","Niveau d'expérience")}</span>
                </Col>

                <Col>
                  <Select
                    className="React"
                    classNamePrefix="select"
                    // defaultValue={{ value: "3", label: "< 3 ans" }}
                    name="levelOfExperience"
                    value={data.levelOfExperience}
                    onChange={value => setData({ ...data, levelOfExperience: value })}
                    options={getFieldOptions("levelOfExperience")}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("skill") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("skills","Compétences")}</span>
                </Col>

                <Col>
                  <Select
                    isMulti
                    name="skill"
                    className="React"
                    classNamePrefix="select"
                    options={getFieldOptions("skill")}
                    value={data.skill}
                    onChange={options => setData({ ...data, skill: options })}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("businessLine") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("domain","Secteur d'activité")}</span>
                </Col>

                <Col>
                  <Select
                    className="React"
                    classNamePrefix="select"
                    name="businessLine"
                    value={data.businessLine}
                    onChange={({ target }) => setData({ ...data, businessLine: target.value })}
                    options={getFieldOptions("businessLine")}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("availability") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("availability","Disponibilité")}</span>
                </Col>

                <Col>
                  <Select
                    className="React"
                    classNamePrefix="select"
                    defaultValue={{ value: "3", label: "Disponible / Ouvert" }}
                    name="availability"
                    value={data.availability}
                    onChange={option => setData({ ...data, availability: option })}
                    options={getFieldOptions("availability")}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("language") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("lang","Langue(s)")}</span>
                </Col>

                <Col>
                  <Select
                    name="language"
                    options={getFieldOptions("language")}
                    value={data.language}
                    onChange={options => setData({ ...data, language: options })}
                    className="React"
                    classNamePrefix="select"
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("eventType") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("event","Type d'évenement")}</span>
                </Col>

                <Col>
                  <Select
                    isMulti
                    name="eventType"
                    options={getFieldOptions("eventType")}
                    value={data.eventType}
                    onChange={option => setData({ ...data, eventType: option })}
                    className="React"
                    classNamePrefix="select"
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("participantsNumber") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("nPart","Nombre de participant")}</span>
                </Col>

                <Col>
                  <Select
                    className="React"
                    classNamePrefix="select"
                    defaultValue={{ value: "3", label: "0-5" }}
                    name="participantsNumber"
                    value={data.participantsNumber}
                    onChange={option => setData({ ...data, participantsNumber: option })}
                    options={getFieldOptions("participantsNumber")}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("musicGenre") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("musicType","Genre de musique")}</span>
                </Col>

                <Col>
                  <Select
                    isMulti
                    name="musicGenre"
                    options={getFieldOptions("musicGenre")}
                    value={data.musicGenre}
                    onChange={options => setData({ ...data, musicGenre: options })}
                    className="React"
                    classNamePrefix="select"
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("locationType") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("locationType","Type de lieu")}</span>
                </Col>

                <Col>
                  <Select
                    isMulti
                    name="locationType"
                    value={data.locationType}
                    options={getFieldOptions("locationType")}
                    onChange={options => setData({ ...data, locationType: options })}
                    className="React"
                    classNamePrefix="select"
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("services") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("services","Service(s)")}</span>
                </Col>

                <Col>
                  <Select
                    isMulti
                    name="services"
                    className="React"
                    classNamePrefix="select"
                    options={getFieldOptions("services")}
                    value={data.services}
                    onChange={options => setData({ ...data, services: options })}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("extraServices") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("extraServices","Service(s) supplémentaire(s)")}</span>
                </Col>

                <Col>
                  <Select
                    isMulti
                    name="extraServices"
                    className="React"
                    classNamePrefix="select"
                    options={getFieldOptions("extraServices")}
                    value={data.extraServices}
                    onChange={options => setData({ ...data, extraServices: options })}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("budget") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("budget","Budget")}</span>
                </Col>

                <Col>
                  <Select
                    className="React"
                    classNamePrefix="select"
                    name="budget"
                    value={data.budget}
                    onChange={({ target }) => setData({ ...data, budget: target.value })}
                    options={getFieldOptions("budget")}
                  />
                </Col>
              </FormGroup>
            )}
            {isFieldEnabled("coverageArea") && (
              <FormGroup row>
                <Col md="4">
                  <span>{t("coverageArea","Zone de service")}</span>
                </Col>

                <Col>
                  <Input
                    placeholder="Zone de service"
                    name="coverageArea"
                    value={data.coverageArea}
                    onChange={({ target }) => setData({ ...data, coverageArea: target.value })}
                  />
                </Col>
              </FormGroup>
            )}

            <Button.Ripple color="primary" type="submit" className="ml-0" disabled={isFetching}>
              {t("finish","Terminer")}
            </Button.Ripple>
          </Form>
        </Col>
        <Col className="d-none d-md-inline-block py-5 px-2 h-100 bg-body">
          <h3 className="m-1">{t("profil","Votre profil")}</h3>
          <PreviewCard
            media={user.photo}
            title={user.firstname + " " + user.lastname}
            subtitle={user.email}
            caption={
              ((user.address && user.address.city) || "") + " - " + (user.address && user.address.country) || "France"
            }
          />
          {currentSpace.structureType && (
            <PreviewCard
              media={currentSpace.photo}
              title={currentSpace.name}
              caption={
                currentSpace.address &&
                (currentSpace.address.city || "") + " - " + (currentSpace.address.country || "France")
              }
            />
          )}
        </Col>
      </Row>
    </div>
  );
}
