import {
  REQUEST_CREATE_SPACE,
  CLEAR,
  SET_CURRENT_SPACE,
  UPDATE_CURRENT_SPACE,
  SET_SPACES,
  FETCH_SPACES,
} from "../../actions/space";

const INITIAL_STATE = { isFetching: false, list: [], currentSpace: null };

const space = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SPACES: {
      switch (action.status) {
        case "fetching":
          return { ...state, isFetching: true };
        case "success": {
          return { ...state, isFetching: false };
        }
        case "error":
          return { ...state, isFetching: false, error: action.error };
        default:
          return state;
      }
    }

    case REQUEST_CREATE_SPACE:
      if (action.success === null) {
        return {
          ...state,
          creating: true,
          error: undefined,
          newSpace: undefined,
        };
      }

      if (action.success === false) {
        return {
          ...state,
          creating: false,
          error: action.error,
          newSpace: undefined,
        };
      }

      return {
        ...state,
        creating: false,
        error: undefined,
        newSpace: action.data,
      };

    case SET_CURRENT_SPACE: {
      if (state.currentSpace) {
        return {
          ...state,
          currentSpace: { ...state.currentSpace, ...action.space },
        };
      } else {
        return {
          ...state,
          currentSpace: action.space,
        };
      }
    }

    case UPDATE_CURRENT_SPACE: {
      switch (action.status) {
        case "fetching":
          return { ...state, isFetching: true, isSuccess: false };
        case "success": {
          let newCurrentSpace = { ...state.currentSpace, ...action.data };

          // update spaces list as well
          let spaces = state.list.map(space => {
            if (space.id === newCurrentSpace.id) {
              return { ...space, ...newCurrentSpace };
            } else {
              return space;
            }
          });
          return {
            ...state,
            currentSpace: newCurrentSpace,
            list: spaces,
            isFetching: false,
            isSuccess: true,
          };
        }
        case "error":
          return { ...state, isFetching: false, error: action.error };
        default:
          return state;
      }
    }

    case SET_SPACES: {
      let newListSpaces = action.spaces;
      if (newListSpaces.includes(state.currentSpace)) return { ...state, list: newListSpaces };
      else {
        let currentSpace = newListSpaces[0] || {};
        return { ...state, list: newListSpaces, currentSpace };
      }
    }

    case CLEAR:
      return {
        ...state,
        creating: false,
        error: undefined,
        newSpace: undefined,
      };
    default:
      return state;
  }
};

export default space;
