import { STORE_REDIRECT_URL } from "../../actions/init";

const init = (state = {}, action) => {
  switch (action.type) {
    case STORE_REDIRECT_URL:
      return {
        ...state,
        redirect:action.payload,
      };
    default:
      return state;
  }
};

export default init;
