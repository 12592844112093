import TitleElementComponent from "components/elements/TitleElementComponent";
import ParagraphElementComponent from "components/elements/ParagraphElementComponent";
import ImageElementComponent from "components/elements/ImageElementComponent";
import FormElementComponent from "components/elements/FormElementComponent";
import FormHeadElementComponent from "components/elements/FormHeadElementComponent";
import FieldCheckboxElementComponent from "components/elements/fields/FieldCheckboxElementComponent";
import FieldEmailElementComponent from "components/elements/fields/FieldEmailElementComponent";
import FieldMediaElementComponent from "components/elements/fields/FieldMediaElementComponent";
import FieldNumberElementComponent from "components/elements/fields/FieldNumberElementComponent";
import FieldPhoneElementComponent from "components/elements/fields/FieldPhoneElementComponent";
import FieldTextareaElementComponent from "components/elements/fields/FieldTextareaElementComponent";
import FieldTextElementComponent from "components/elements/fields/FieldTextElementComponent";

export const TITLE = "Title";
export const PARAGRAPH = "Paragraph";
export const IMAGE = "Image";
export const FORM = "Form";
export const FORM_HEAD = "FormHead";
export const FIELD_TEXT = "FieldText";
export const FIELD_TEXTAREA = "FieldTextarea";
export const FIELD_NUMBER = "FieldNumber";
export const FIELD_CHECKBOX = "FieldCheckbox";
export const FIELD_EMAIL = "FieldEmail";
export const FIELD_PHONE = "FieldPhone";
export const FIELD_MEDIA = "FieldMedia";

export const ELEMENTS_TYPES = [
  TITLE,
  IMAGE,
  PARAGRAPH,
  FORM,
  FIELD_TEXT,
  FIELD_TEXTAREA,
  // FIELD_SELECT,
  FIELD_EMAIL,
  FIELD_PHONE,
  FIELD_MEDIA,
  FIELD_CHECKBOX,
  FIELD_NUMBER,
  FORM_HEAD,
];

export const PAGE_TYPE_FORM = "form";
export const PAGE_TYPE_PAGE = "page";
export const PAGE_TYPE_MENU = "menu";

export const PAGE_STATUS_PUBLISHED = "published";
export const PAGE_STATUS_DRAFT = "draft";

export const TEMPLATE_CUSTOM = "custom";
export const TEMPLATE_CONTACT = "contact";
export const TEMPLATE_MEMBERSHIP = "membership";

export const PAGE_TEMPLATES = [TEMPLATE_MEMBERSHIP, TEMPLATE_CONTACT, TEMPLATE_CUSTOM];

export const elements = {
  [TITLE]: TitleElementComponent,
  [IMAGE]: ImageElementComponent,
  [PARAGRAPH]: ParagraphElementComponent,
  [FORM]: FormElementComponent,
  [FIELD_TEXT]: FieldTextElementComponent,
  [FIELD_TEXTAREA]: FieldTextareaElementComponent,
  [FIELD_EMAIL]: FieldEmailElementComponent,
  [FIELD_PHONE]: FieldPhoneElementComponent,
  [FIELD_MEDIA]: FieldMediaElementComponent,
  [FIELD_CHECKBOX]: FieldCheckboxElementComponent,
  [FIELD_NUMBER]: FieldNumberElementComponent,
  [FORM_HEAD]: FormHeadElementComponent,
};

export function getElementComponent(type) {
  return elements[type];
}
