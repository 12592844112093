import React, { lazy, useEffect, useState } from "react";
import { Row, Col, Alert, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PreviewCard from "./partials/PreviewCard";

import "../../../../assets/scss/pages/authentication.scss";
import logo from "../../../../assets/img/logo/hibim-logo.png";
import { useTrad } from "../../../../utility/traduction";
import { getPagesByTemplate, getPageElements } from "../../../../redux/actions/pages";
import { TEMPLATE_MEMBERSHIP, PAGE_STATUS_PUBLISHED } from "../../../../configs/elements";
import { submitForm } from "../../../../services/page";

function compare(a, b) {
  const orderA = a.order || 0;
  const orderB = b.order || 0;
  if (orderA !== orderB) {
    return orderA > orderB ? 1 : -1;
  } else {
    return 0;
  }
}

export default function MembershipForm({ onFinalStep }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useTrad("HibimAccount_register_MemberShipForm");

  const user = useSelector(state => state.auth.user) || {};

  const membershipForm = useSelector(state => state.pages.current);
  const pageStatus = useSelector(state => state.pages.status.getPages);
  const membershipElements = useSelector(state => state.pages.elements);
  const elementStatus = useSelector(state => state.pages.status.getElements);

  const space = new URLSearchParams(history.location.search).get("space");
  const spaces = useSelector(state => state.spaces.list);
  const currentSpace = spaces?.find(s => s.slug === space);

  const [submitAlert, setSubmitAlert] = useState(elementStatus === "error" || pageStatus === "error");
  const [successAlert, setSuccessAlert] = useState(false);

  useEffect(() => {
    if (currentSpace) {
      dispatch(getPagesByTemplate(TEMPLATE_MEMBERSHIP, currentSpace));
    }
  }, [dispatch, currentSpace]);

  useEffect(() => {
    if (membershipForm) {
      dispatch(getPageElements(membershipForm.id));
    }
  }, [dispatch, membershipForm]);

  if (pageStatus === "success" && membershipForm?.status !== PAGE_STATUS_PUBLISHED) {
    onFinalStep();
  }

  const handleSubmit = async formData => {
    try {
      await submitForm(formData, membershipForm, currentSpace);
      onFinalStep();
    } catch (e) {
      console.log(e);
      setSubmitAlert(true);
    }
  };

  const renderElement = element => {
    const componentName = `${element.type.replaceAll("_", "")}ElementComponent`;
    const Component = lazy(() =>
      import(`../../../../components/elements/${element.type.includes("Field") ? "fields/" : ""}${componentName}`),
    );
    return <Component key={element.id} element={element} onSubmit={handleSubmit} />;
  };

  return (
    <div className="vh-100">
      <header className="py-1 px-5 shadow-sm bg-white">
        <img src={logo} alt="" />
      </header>
      <Row className="h-100">
        <Col className="h-100 p-5 bg-white">
          <Row>
            <Col sm="12">
              <Alert
                className="mb-2"
                color="warning"
                isOpen={submitAlert}
                toggle={() => {
                  setSubmitAlert(false);
                }}
              >
                <p className="mb-0">{t("error", "Il semble qu'il y ait eu une erreur...")}</p>
              </Alert>
              <Alert
                className="mb-2"
                color="success"
                isOpen={successAlert}
                toggle={() => {
                  setSuccessAlert(false);
                }}
              >
                <p className="mb-0">{t("success", "Les modifications ont bien étés apportées !")}</p>
              </Alert>
            </Col>
          </Row>
          {(!currentSpace || elementStatus === "fetching" || pageStatus === "fetching") && (
            <Spinner color="primary" size="lg" lassName="my-5" />
          )}
          {membershipElements?.sort(compare).map(element => renderElement(element))}
        </Col>
        <Col className="d-none d-md-inline-block py-5 px-2 h-100 bg-body">
          <h3 className="m-1">{t("profil", "Votre profil")}</h3>
          <PreviewCard
            media={user.photo}
            title={user.firstname + " " + user.lastname}
            subtitle={user.email}
            // caption={
            //   ((user.address && user.address.city) || "") + " - " + (user.address && user.address.country) || "France"
            // }
          />
        </Col>
      </Row>
    </div>
  );
}
